
import { defineComponent, onMounted, ref } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import RegimentoInternoColumn from "@/util/table-columns/RegimentoInternoColumn";
import RegimentoInternoForm from "@/components/admin/regimento-internos/RegimentoInternoForm.vue";
import useList from "@/modules/useList";
import Curso from "@/interfaces/Curso";
import BaseService from "@/services/admin/BaseService";
import DivisaoService from "@/services/auth/DivisaoService";
import store from "@/store";

export default defineComponent({
  components: { RegimentoInternoForm },
  setup() {
    const $q = useQuasar();
    const cursos = ref<Curso[]>([]);
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList("regimento-internos", RegimentoInternoColumn);

    const getCursos = (): void => {
      loading.value = true;

      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("cursos")
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getCursos();
    });

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      getCursos,
      cursos,
      confirmDelete,
    };
  },
});
