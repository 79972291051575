
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import FormLoading from "../../layout/FormLoading.vue";
import BaseService from "@/services/admin/BaseService";
import useForm from "@/modules/useForm";

interface Form {
  titulo: string;
  anexo: string;
  curso_id: string;
}

const baseForm: Form = {
  titulo: "",
  anexo: "",
  curso_id: "",
};

export default defineComponent({
  components: { FormLoading },
  props: {
    cursos: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const $q = useQuasar();
    const { show, form, row, loading, status, afterSubmit } = useForm(
      "regimento-internos",
      "regimento interno",
      "o",
      baseForm,
      emit,
      "regimento-internos"
    );

    const open = (data: any | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            titulo: row.value.titulo,
            curso_id: row.value.curso.id,
            anexo: ''
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
        }
      }
      show.value = true;
    };

    const submit = (): void => {
      if (!row.value?.id) {
      let formData = new FormData();
      formData = buildFormData(formData, form.value);
      formData.append("anexo", form.value.anexo);
      BaseService.store("regimento-internos", formData)
        .then(() => {
          emit("update");
          show.value = false;
          showNotify({
            type: "positive",
            message: `Regime Interno inserido com sucesso`,
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: "Erro ao adicionar regime interno",
          });
        });
      } else {
        let formData = new FormData();
        formData = buildFormData(formData, form.value);
        formData.append("anexo", form.value.anexo);
        BaseService.update("regimento-internos", row.value.id, formData)
          .then(() => {
            emit("update");
            show.value = false;
            showNotify({
              type: "positive",
              message: `Regime Interno inserido com sucesso`,
            });
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: "Erro ao adicionar regime interno",
            });
          });
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const buildFormData = (
      formData: any,
      data: any,
      parentKey: any = null
    ): FormData => {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
      return formData;
    };

    return {
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      afterSubmit,
    };
  },
});
