import RegimentoInterno from "@/interfaces/RegimentoInterno";

const RegimentoInternoColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    style: "width: 100px",
    sortable: false,
  },
  {
    name: "titulo",
    align: "left",
    label: "Título",
    field: "titulo",
    sortable: true,
  },
  {
    name: "status",
    align: "left",
    label: "Status",
    field: "status",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: RegimentoInterno) => row.created_at,
    sortable: true,
  },
];

export default RegimentoInternoColumn;
